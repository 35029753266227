@import './mixins.style.scss';

$card-pad: 3rem;
$inset-pad: 2rem;
$desktop-top-pad: 6vh;
$input-pad: 5rem; // Padding between inputs, vertically
$input-half-pad: 3rem; // Padding between half-size inputs, horizontally

.page-background {
	background-color: #424242;
	background-image: url('/assets/images/aaRegister_background.webp');
	background-position: center top;
	background-size: cover;
	background-repeat: no-repeat;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	position: absolute;
}

.logo {
	padding-top: $card-pad; // padding to provide visual relief
	width: 60%;
	min-width: 18rem;
	max-width: 30rem;

	//@include phone {
	//	padding-top: 0; // No padding on mobile
	//}
}

.main-card-button {
	justify-content: center;
	height: 56px;

	@include desktop {
		width: 60%;
	}
	@include phone-portrait {
		width: 100%;
	}
}


.inset-card-container {
	overflow-y: auto;
	display: flex;
	justify-content: center;
	align-items: center;

	@include desktop {
		padding: $inset-pad;
		max-width: calc(100% - #{$inset-pad} * 2);
		max-height: calc(100% - #{$inset-pad} * 2);
		height: calc(100% - #{$inset-pad} * 2);
	}
	@include phone {
		padding: 0;
		max-width: 100%;
		max-height: 100%;
		height: 100%;
		width: 100%;
	}
}

.card-fullheight {
	height: 100vh;
	overflow-y: auto;
	background-color: white;
	padding: 40px 48px 24px 48px;
	position: relative;
	@include desktop {
		width: 498px;
	}
	@include phone {
		width: 100%;
	}
}

.card {

	position: relative;
	height: max-content;
	overflow-y: auto;
	border-radius: 1px;
	box-shadow: 0 0 10px 1px rgb(0 0 0 / 35%);
	background-color: var(--white);
	display: flex;
	flex-flow: column;

	.card-header {
		padding: 0 0 $card-pad 0;
	}

	.card-header {
		text-align: center;
	}

	.card-body {
		position: relative;
		display: inline-block;
		width: 100%;
		padding-bottom: 3rem;
	}

	.card-footer {
		position: relative;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		gap: 3rem;
	}

	.card-actions-relative {
		position: relative;
		max-width: 100%;
	}

	.card-actions-container {

	}

	.card-actions {
		width: 100%;
		position: relative;
		bottom: 0;
		right: 0;
		display: flex;
		align-items: baseline;
		justify-content: center;
		flex-direction: row;

		.logout-button {
			height: 36px;
		}
	}

	.card-info {
		@extend .H6Black700Semibold;
		padding-bottom: 2rem;
	}

	@include desktop {
		width: 468px;
		max-height: calc(100% - #{$inset-pad} - #{$desktop-top-pad} - #{$card-pad} * 2); // 16px + 6vh (inset padding) + 24px * 2 (card padding)
		padding: $card-pad 48px;
	}
	@include tablet {
		width: 468px;
		max-height: calc(100% - #{$inset-pad} * 2 - #{$card-pad} * 2); // 16px * 2 (inset padding) + 24px * 2 (card padding) = 80px
		padding: $card-pad 32px;
	}
	@include phone {
		width: 100%;
		padding: $card-pad;
		height: 100%;
		max-height: calc(100% - #{$card-pad} * 2); // 16px * 2 (inset padding) + 24px * 2 (card padding) = 80px
		justify-content: center;
		margin-bottom: 0;
	}
}

.nav-container {
	@extend .H6Black700Regular;

	text-align: center;
	color: var(--black-700);
	padding-top: 3rem;
}

.nav-link {
	@extend .H6Blue500Regular;
	text-decoration: underline;
	text-align: center;
	cursor: pointer;
}

.input-header {
	@extend .H6Black700Regular;
	position: relative;
	padding-bottom: 8px;
	height: 24px;
}

.password-input-header {
	padding-bottom: 1rem;
	right: 0;
	position: relative;
	display: flex;
	justify-content: flex-end;
}

.card-sub-title {
	@extend .LabelBlack500;
	@extend .uppercase;
}

.card-title {
	padding-bottom: 24px;
	@extend .H3Black700;
}

.google-signup {
	width: 196px;
	height: 40px;
	float: right;
}

.logo-container {
	text-align: center;
	overflow: hidden;
}

.logo-icon {
	height: 32px;
	width: 32px;
	cursor: pointer;
}

.button-subscribe {
	width: 100%;
	height: 56px;
	border-radius: 4px;
	border: solid 1px var(--black-400);
	background-color: var(--black-400);
}

.next-button {
	justify-content: center;
	width: 136px;
	height: 56px;
	float: right;
}

.input-full-size {
	padding-top: 3rem;
	display: flex;
	flex-direction: column;
	position: relative;

	&:last-of-type {
		padding-bottom: 1rem;
	}
}

.input-half-size {
	position: relative;

	@include desktop {
		width: 50%;
	}
	@include phone {
		width: 100%;
		padding-bottom: 0;
	}
}

.flex-row-desktop {
	display: flex;
	@include desktop {
		flex-direction: row;
	}
	@include phone-portrait {
		flex-direction: column;
	}
}

.flex-column-desktop {
	display: flex;
	@include desktop {
		flex-direction: column;
	}
	@include phone-portrait {
		flex-direction: row;
	}
}

.input-half-size-left {
	@extend .input-half-size;
	@include desktop {
		padding-right: $input-half-pad;
	}
	@include phone-portrait {
		padding-bottom: $input-pad;
	}
}

.custom-google-button{
	-webkit-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background-color .218s,border-color .218s,box-shadow .218s;
    transition: background-color .218s,border-color .218s,box-shadow .218s;
    background-color: #fff;
    color: #262626;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
	display: flex;
	align-items: center;

	&:hover{
		cursor: pointer;
		-webkit-box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
		box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
	}

	.gText{
		font-family: "Muli", sans-serif;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: .21px;
		padding: 0 1rem;
		color: #757575;
		line-height: 56px;
		max-width: calc(100% - 7rem);
	}
}

.custom-google-icon{
	//background-image: url('https://design.google/static/images/google-g.svg');
	background: url('/assets/google/btn_google_light_normal_justG.svg') transparent no-repeat;
	background-size: cover;
	display: inline-block;
	vertical-align: middle;
	width: 7rem;
	height: 7rem;
}

.policy {
	text-decoration: underline;
	cursor: pointer;
}

.invalid {

	.input-header,
	.error-text {
		color: #d70000;
	}
	input {
		border-color: #d70000 !important;
		outline: none;
	}
}

.error-text {
	@extend .P2Red;
}

.signin-divider {
	color: var(--black-400);
	text-align: center;

	.half-line {
		height: 1px;
		width: 22%;
		background-color: var(--black-400);
		display: inline-block;
		vertical-align: middle;
		margin: 0rem 1rem;
	}
}

.centered {
	text-align: center;
}

