
/* ----------- Desktop ----------- */
@mixin desktop {
	@media all
		and (min-width:480px) {
			@content;
	}
}

/* ----------- Phone ----------- */
@mixin phone {
	@media (max-width:480px) and (orientation:portrait) {
		@content;
	}
	@media (max-height:480px) and (orientation:landscape) {
		@content;
	}
}

/* ----------- Phone Portrait ----------- */
@mixin phone-portrait {
	@media (max-width:480px) and (orientation:portrait) {
		@content;
	}
}

/* ----------- Phone Landscape ----------- */
@mixin phone-landscape {
	@media (max-height:480px) and (orientation:landscape) {
		@content;
	}
}

/* ----------- Tablet ----------- */
@mixin tablet {
	@media only screen
	and (max-width: 1024px) { // Defined as iPad Pro width
		@content;
	}
}

/* ----------- iPad ----------- */
@mixin ipad {
	@media handheld,
		only screen
		and (min-width: 768px)
		and (max-height: 1024px)
		and (-webkit-min-device-pixel-ratio: 1.5)
		and (hover: none) {
			@content;
	}
}

/* ----------- iPad Pro ----------- */
@mixin ipadpro {
	@media handheld,
	only screen
	and (min-width: 1020px)
	and (max-height: 1370px)
	and (-webkit-min-device-pixel-ratio: 1.5)
	and (hover: none) {
		@content;
	}
}

.tablet-hidden {
	@include tablet {
		display: none !important;
	}
}

.phone-hidden {
	@include phone {
		display: none !important;
	}
}

.tablet-visible {
	display: none !important;
	@include tablet {
		display: block !important;
	}
}

.phone-visible {
	display: none !important;
	@include phone {
		display: block !important;
	}
}

.tablet-visible {
	display: none !important;
	@include tablet {
		display: block !important;
	}
}
