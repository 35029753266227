
$bar-padding: 3rem;
$bar-height: 72px;

.spinning-loader {
	margin: auto;
	border: 3px solid #f3f3f3; /* Light grey */
	border-top: 3px solid var(--black-500); /* Blue */
	border-radius: 50%;
	width: 14px;
	height: 14px;
	animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	40% { -webkit-transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	40% { transform: rotate(180deg); }
	100% { transform: rotate(360deg); }
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.top-bar {
	background-color: var(--black-500);
	width: 100%;
	display: flex;
	min-height: $bar-height;
	cursor: pointer;

	.bar-content {
		@extend .H6WhiteSemibold;
		padding: 0 $bar-padding;
		display: flex;
		justify-content: space-between;
		width: 100%;
		overflow: hidden;

		@include phone {
			font-size: 14px; // Defined to shrink font sizes on mobile
		}

		.bar-content-inner {
			display: flex;
			align-items: center;
			max-width: calc(100% - 32px);
		}

		.bar-status {
			display: flex;
			width: 20px;
			margin: auto 16px auto auto;

			mat-icon {
				font-size: 20px;
				margin: auto;
			}
		}

		.bar-text {
			padding-right: 16px; // Separation from the icons, in the case of a longer title
			margin-right: auto;
			font-size: 16px;
		}

		.expand-icons {
			padding-right: 2rem; // Separation from DISMISS button

			mat-icon {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

.bar-body {
	width: 100%;
	display: flex;
	padding: 0.5rem 0;

	.bar-list {
		width: 100%;
		height: max-content;
		margin: 0;
		padding: 0;
		overflow-x: hidden;

		.bar-list-item {
			display: block;
			height: 100%;
			border-bottom: solid 1px #aeb3b9;
			cursor: default;
		}
	}
}

.details {
	height: auto;
	display: block;
	padding: 2rem 1rem;
	width: calc(100% - 2rem); // 48px from padding
	overflow-x: hidden;

	.details-title-container {
		display: flex;
		@include phone-portrait {
			flex-direction: column; // For vertical phones, moves the text below
		}
	}

	.details-title {
		@extend .H6Blue500Regular;
		@extend .ellipsis;

		text-decoration: underline;
		margin-right: 8px;
		margin-bottom: 4px;
		cursor: pointer;
		@include phone-portrait {
			width: 100%;
		}
	}

	.processed-text {
		@extend .H7Black500Regular;
		margin-bottom: 8px;
		white-space: nowrap;
	}
}

.bar-view {
	background-color: white;
	display: flex;
	transition: 0.3s ease-out all;
	height: calc(100% - #{$bar-height});
	overflow-y: auto;
	border: solid 1px var(--black-500);
	border-radius: 0 0 4px 4px;

	@include phone {
		max-height: calc(100vh - 56px - #{$bar-height} - 16px - 16px); // 56 (button) 72px (top bar)
	}
}

.shrunk {
	max-height: 0;
	border: none;
	transition: 0.2s ease-out all;
}

.dropdown-button {
	@extend .H7Black700Regular;
	display: flex;
	padding: 5px 8px;
	height: 32px;
	margin: auto 0 auto 8px;
	border-radius: 32px;
	transform: rotate(0deg);
	transition: 0.2s ease-in-out;
	margin-right: 16px;

	mat-icon {
		margin-left: 4px;
		transition: 0.2s ease-in-out;
	}

	&.expanded {
		background-color: #dee0e3;

		mat-icon {
			transform: rotate(180deg);
		}
	}
}
