
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use './styleguide';
@import "./app/shared/styles/three-dots/three-dots.scss";
@import "./app/shared/styles/mixins.style";
@import "./app/shared/styles/floating-bar.style";
@import './app/shared/styles/inset-card.style.scss';
@import './app/shared/styles/react.style.scss';
@import './app/shared/styles/skeleton.style.scss';
@import './app/shared/styles/utilities.style.scss';

// DO NOT PLACE STYLING FOR COMPONENTS HERE.
// THIS SPACE IS RESERVED FOR GLOBAL STYLES
:root {

	--phone-width: 480px;
	--phone-height: 480px;

	--tablet-width: 1020px;
	--tablet-height: 1370px;

	--black-700-opacity: rgba(57, 63, 73, 0.7);
	--black-700: #252525;
	--black-600: #393f49;
	--black-500: #6a7483;
	--black-450: #8d9196;
	--black-400: #aeb3b9;
	--black-300: #dee0e3;
	--black-200: #f5f7fa;
	--black-200-opacity: #2223241f;

	--blue-700: #002255;
	--blue-600: #012c6c;
	--blue-500: #024ec0;
	--blue-400: #5588d4;
	--blue-300: #cbdaf2;
	--blue-200: #f1f6fd;

	--gray-blue: #93BFD2;
	--gray-blue-10: #93BFD220;
	--gray-blue-25: #93BFD23F;
	--gray-blue-50: #93bfd27f;
	--gray-blue-100: #f1f7f9;

	--red: #d70000;
	--darker-red: #b22317;
	--pink: #ffe2e0;
	--cerulean-blue: #005dea;
	--asdfff: #e6edf8;
	--white: #ffffff;
	--white-200-opacity: #ffffff1f;
	--squash: #edb716;
	--green: #008000;
	--turquoise-blue: #02adc0;
	--dark-gray: #202020;
	--dark-black: #111;
	--light-black: #1a1a1a;
	--light-red: #d85656;
	--very-light-red: #ffeeec;
	--slate-grey: #636c79;
	--red-brown: #55322c;

	--mat-select-trigger-text-font: Muli,sans-serif;
	--mdc-list-list-item-label-text-font: Muli,sans-serif;
	--mat-tab-header-label-text-font: Muli,sans-serif;
	--mdc-outlined-button-label-text-font: Muli,sans-serif;
	--mdc-text-button-label-text-font: Muli,sans-serif;
	--mat-form-field-container-text-font: Muli,sans-serif;
	--mdc-filled-text-field-label-text-font: Muli,sans-serif;
	--mdc-filled-button-label-text-font: Muli,sans-serif;
	--mat-option-label-text-font: Muli,sans-serif;
	--mat-switch-label-text-font: Muli,sans-serif;
	--mat-menu-item-label-text-font: Muli,sans-serif;
	--mat-checkbox-label-text-font: Muli,sans-serif;
	--mdc-filled-button-container-color: #005dea;
	--mdc-outlined-button-label-text-color: #005dea;
	--mdc-tab-indicator-active-indicator-color: #005dea;
	--mdc-outlined-button-outline-color: #005dea;
	--mdc-filled-button-label-text-color: #ffffff;
	--mdc-filled-text-field-caret-color: black;
	--mdc-filled-button-container-shape: 4px;
	--mdc-outlined-button-container-shape: 4px;
	--mdc-text-button-container-shape: 4px;
	--mat-tab-header-label-text-size: 14px;
	--mat-tab-header-divider-color: #dee0e3;
	--mat-tab-header-label-text-line-height: 4px;
	--mdc-icon-button-state-layer-size: 40px;
	--mdc-outlined-button-label-text-size: 14px;
	--mdc-outlined-text-field-container-shape: 8px;
	--mdc-filled-button-padding: 0px;
	--mat-text-button-horizontal-padding: 16px;
	--mdc-list-list-item-label-text-size: 14px;
	--mdc-dialog-container-shape: 4px;
	--mat-divider-color: #cccccc;
	--mat-table-row-item-outline-color: #cccccc;
	--mat-dialog-container-max-width: 80vw;
	--mat-menu-container-color: #ffffff;
	--mdc-outlined-text-field-hover-outline-color: var(--black-300);
	--mat-select-panel-background-color: #ffffff;
	--mat-table-background-color: #ffffff;
	--mdc-filled-text-field-container-color: var(--black-200);
	--mat-paginator-container-text-size: 12px;
	--mat-paginator-select-trigger-text-size: 14px;
	--mdc-list-list-item-one-line-container-height: 48px;
	--mat-expansion-header-text-line-height: 1.5;
	--mdc-list-list-item-label-text-line-height: 1.5;
	--mat-select-trigger-text-line-height: 1.5;
	--mat-checkbox-label-text-line-height: 1.5;
	--mat-option-label-text-line-height: 1.5;
	--mat-text-button-icon-offset: 0;
	--mat-filled-button-icon-offset: 0;
	--mat-text-button-icon-spacing: 0;
	--mat-outlined-button-icon-offset: 0;
	--mat-outlined-button-icon-spacing: 0;
	--mdc-text-button-label-text-size: 14px;
	--mat-checkbox-label-text-size: 14px;
	--mdc-filled-button-label-text-size: 16px;
	--mat-select-trigger-text-size: 14px;
	--mat-option-label-text-size: 14px;
	--mat-form-field-container-text-size: 14px;
	--mdc-filled-text-field-label-text-size: 14px;
	--mat-menu-item-label-text-size: 14px;
	--mat-form-field-container-height: 40px;
	--mdc-outlined-button-container-height: 32px;
	--mdc-text-button-container-height: 32px;
	--mdc-filled-button-container-height: 56px;
	--mdc-plain-tooltip-container-color: var(--black-500);
	--mdc-filled-text-field-error-caret-color: #6e0000;
	--mdc-plain-tooltip-supporting-text-color: white;
	--mdc-plain-tooltip-container-shape: 2px;
	--mdc-plain-tooltip-supporting-text-font: Muli,sans-serif;
	--mdc-plain-tooltip-supporting-text-size: 12px;
	--mdc-plain-tooltip-supporting-text-line-height: 1.5;
	--mat-form-field-hover-state-layer-opacity: 0.02;
	--mat-form-field-filled-label-display: inline-flex;
	--mat-switch-label-text-size: 14px;
	--mat-switch-pressed-handle-size: 20px;
	--mdc-switch-track-height: 24px;
	--mdc-switch-state-layer-size: 24px;
	--mat-switch-with-icon-handle-size: 20px;
	--mdc-switch-track-width: 48px;
	--mat-dialog-headline-padding: 1rem 0;
	--mat-menu-divider-top-spacing: 0;
	--mat-menu-divider-bottom-spacing: 0;

	@include desktop {
		font-size: 8px; // Defines the REM base value to be 8px
	}

	@include phone {
		font-size: 6px; // Defines the REM mobile value to be 6px
	}
}


// z-index baseline is set to 1.
// 1 is defined because if one needs to assign a z-index, it'll be at least above the base level of 0.
// 0 would cause issues, where it would then be based on whichever one was created after the other.
// If for some reason one needs to heighten every element, adjust only the base level.

$z-index-base: 1;

$z-index-viewer-top: 700;

// To add another level here, set the value to 1 padding level above the previous.
$z-index-padding: 100; // Padding for index to have a number of indexes available between major levels for future-proofing
$z-index-menu: $z-index-base + $z-index-padding;
$z-index-overlay: $z-index-menu + $z-index-padding;
$z-index-alert: $z-index-overlay + $z-index-padding + $z-index-viewer-top;

$s0_5: 4px;			// 8px
$s1: 8px;			// 8px
$s1_5: $s1 * 1.5; 	// 12px
$s2: $s1 * 2; 		// 16px
$s2_5: $s1 * 2.5; 	// 20px
$s3: $s1 * 3; 		// 24px
$s4: $s1 * 4; 		// 32px
$s5: $s1 * 5; 		// 40px
$s6: $s1 * 6; 		// 48px
$s7: $s1 * 7; 		// 56px
$s8: $s1 * 8; 		// 64px
$s9: $s1 * 9; 		// 72px
$s10: $s1 * 10; 	// 80px
$s11: $s1 * 11; 	// 88px
$s12: $s1 * 12; 	// 96px

$grid_height: 380px;
$grid_width: 448px;

$border-radius: 4px;

.horizontal-s1 { margin: 0 $s1; }
.horizontal-s2 { margin: 0 $s2; }
.horizontal-s3 { margin: 0 $s3; }
.horizontal-s4 { margin: 0 $s4; }
.horizontal-s8 { margin: 0 $s8; }
.horizontal-s12 { margin: 0 $s12; }

.vertical-s1 { margin: $s1 0; }
.vertical-s2 { margin: $s2 0; }
.vertical-s3 { margin: $s3 0; }
.vertical-s4 { margin: $s4 0; }
.vertical-s8 { margin: $s8 0; }
.vertical-s12 { margin: $s12 0; }

.top-s1 { margin-top: $s1; }
.top-s2 { margin-top: $s2; }
.top-s3 { margin-top: $s3; }
.top-s4 { margin-top: $s4; }
.top-s8 { margin-top: $s8; }
.top-s12 { margin-top: $s12; }

.bottom-s1 { margin-bottom: $s1; }
.bottom-s2 { margin-bottom: $s2; }
.bottom-s3 { margin-bottom: $s3; }
.bottom-s4 { margin-bottom: $s4; }
.bottom-s8 { margin-bottom: $s8; }
.bottom-s12 { margin-bottom: $s12; }

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		 -khtml-user-select: none; /* Konqueror HTML */
			 -moz-user-select: none; /* Old versions of Firefox */
				-ms-user-select: none; /* Internet Explorer/Edge */
						user-select: none; /* Non-prefixed version */
}

span:focus {
	outline: none;
}

.hidden {
	position: absolute;
	opacity: 0;
	pointer-events: none;
}

.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: lowercase;
}

.disabled {
	pointer-events: none;
	opacity: 0.3;
	border-color: var(--black-400) !important;
	color: var(--black-400) !important;

	& * {
		pointer-events: none !important;
	}
}

// Viewer
canvas:focus {
	outline: none !important;
}


.measure_angle_container {
}

.measure_angle_icon{
	transform: translateX(-32px);
	background: rgba(0, 0, 0, 0.5);
	color: white;
	text-align: center;
	font-size: 14px;
	border-radius: 6px;
	width: 64px;
	pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance:textfield;
}



.jstree-default-aa>.jstree-container-ul>.jstree-node {
	// display: flex !important;
	width: calc(100% - 24px) !important;
}

.jstree-default-aa .jstree-anchor {
	width: 100% !important;
	padding: 8px 0px;
}

.jstree-icon.jstree-checkbox {
	float: right !important;
}

.jstree-children:first-child {
	// padding: 12px 0px;
}

.jstree-children {
	padding: 8px 0px !important;
}


.jstree-default-aa>.jstree-no-dots .jstree-open>.jstree-ocl {
	margin: 8px 0px !important;
}

.jstree-icon.jstree-ocl {
	margin: 8px 0px !important;
}

/* jsTree */
.jstree-container-ul {
	opacity: 0.87 !important;
	font-family: Muli !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1.5 !important;
	letter-spacing: 0.1px !important;
	color: var(--white) !important;
}

// .jstree-themeicon-custom {
// 	background-size: 16px !important;
// }

.jstree-default-aa .jstree-clicked {
	// background-color: rgba(256,256,256,0.1) !important;
	background: transparent !important;
}

.jstree-default-aa .jstree-hovered {
	// background-color: rgba(256,256,256,0.25) !important;
	background: transparent !important;
}
