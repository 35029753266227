

// SKELETON

.skel-container {
	padding-top: 24px;
}


.skel-row-item {
	width: 100%;
	height: 135px;
	border: solid 1px #dee0e3;
	border-radius: 4px;
	display: flex;
	overflow: hidden;
	margin: 0 12px;

	.skel-list-image-container {
		width: 200px;
		height: 100%;
		display: flex;
		background-color: var(--black-200);
	}

	.skel-list-text-container {
		margin: auto 24px;

		.skel-list-top {
			display: inline;
		}

		.skel-text-date {
			width: 264px;
			height: 16px;
			margin-right: 94px;
			border-radius: 4px;
			background-color: var(--black-200);
		}
	}

	.skel-list-options {
		margin: auto;
		margin-right: 44px;
		display: flex;

		.skel-text-tag {
			border-radius: 4px;
			width: 200px;
			height: 16px;
			position: relative;
			background-color: var(--black-200);
		}
	}
}

.skel-title {
	width: 216px;
	height: 24px;
	margin-bottom: 8px;
	border-radius: 4px;
	background-color: var(--black-200);
}

.skel-common-item {
	@extend .skel-row-item;
	height: 70px;
	border: none;
	border-bottom: solid 1px #dee0e3;
	border-radius: 0;

	.skel-list-image-container {
		width: 70px;
	}
}

.skel-header {
	@extend .skel-common-item;
	height: 40px;

	.skel-list-title {
		@extend .skel-list-options;
		margin-left: 94px;
		.skel-text-tag {
			width: 120px;
		}
	}
}

.skel-list-image {
	@extend .skel-common-item;
	height: 40px;
	width: 40px;
	margin-right: 16px;
}

.skel-shimmer {
	animation : shimmer 4s infinite;
	background: linear-gradient(45deg, var(--black-200) 40%, rgba(256,256,256,0.5) 50%, var(--black-200) 60%);
	background-size: 1000px 100%;
}


.skel-list-count {
	@extend .skel-shimmer;
	width: 123px;
	height: 19px;
	border-radius: 4px;
	background-color: var(--black-200);
	color: transparent;
}

.skel-list-sort {
	@extend .skel-shimmer;
	width: 140px;
	height: 19px;
	border-radius: 4px;
	background-color: var(--black-200);
	color: transparent;
	overflow: hidden;
	margin: auto 0;
}

.view-skel-item{
	border-radius: 4px;
	background-color: var(--black-200);
	color: transparent;
	overflow: hidden;
	height: 19px;
}

.view-skel-shimmer {
	animation: shimmer 4s infinite;
	background: linear-gradient(45deg, var(--black-200-opacity) 40%, rgba(256,256,256,0.5) 50%, var(--black-200-opacity) 60%);
	background-size: 1000px 100%;
}



@keyframes shimmer{
	0% {
		background-position: -1000px 0;
	}
	100%{
		background-position: 1000px 0;
	}
}
