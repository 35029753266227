// ==============================================
// Variables
// ==============================================

$dot-width: 10px !default;
$dot-height: 10px !default;
$dot-radius: calc(#{$dot-width}/2) !default;

$dot-color: #ffffff !default;
$dot-bg-color: $dot-color !default;
$dot-before-color: $dot-color !default;
$dot-after-color: $dot-color !default;

$dot-spacing: calc(#{$dot-width} + #{$dot-width}/2) !default;
