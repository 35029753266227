
@use '@angular/material' as mat;
@include mat.core();
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@import './styleguide';
@import './styles';
@import './app/shared/styles/mixins.style';
@import './app/shared/styles/aerial-palette';

/* ======== Angular material custom themes ======== */
$my-custom-primary: mat.m2-define-palette(mat.$m2-light-blue-palette);
$my-custom-accent: mat.m2-define-palette(mat.$m2-blue-gray-palette, 800, 800, 800);
$my-custom-warn: mat.m2-define-palette(mat.$m2-amber-palette);
$my-typography: mat.define-typography();
$my-custom-theme: mat.define-theme((
	color: (
		theme-type: light,
		primary: mat.$azure-palette,
		tertiary: mat.$blue-palette
	),
	typography: (
		brand-family: 'Muli, Roboto',
		bold-weight: 800,
	),
	density: (
		scale: -5
	),
));

// Define a dark theme
$dark-theme: mat.define-theme((
	color: (
		theme-type: dark,
		primary: mat.$violet-palette,
		tertiary: mat.$cyan-palette
	)
));

// Define a light theme
$light-theme: mat.define-theme((
	color: (
		theme-type: light,
		primary: mat.$violet-palette,
		tertiary: mat.$cyan-palette,
	)
));

html {
	font-family: 'Muli, Roboto', sans-serif;
	font-size: 16px;
	@include mat.core-theme($my-custom-theme);
	@include mat.all-component-themes($my-custom-theme);
}

@font-face {
	font-family: 'Material Icons Outlined'; // Add Outlined if needed
	font-weight: 200; // The chosen font-weight
	font-style: normal;
	src:
		local('Material Icons Outlined'), // Add Outlined if needed
		url('https://fonts.gstatic.com/s/materialsymbolsoutlined/v166/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDAvHOejbd5zrDAt.woff2') format('woff2');
	font-display: fallback;
}


$button-primary-color: var(--cerulean-blue);

$slide-toggle-bar-color: var(--black-400);
$slide-toggle-thumb-color: var(--slate-grey);
$slide-toggle-thumb-checked: var(--cerulean-blue);

$disabled-font-color: var(--black-300);

$mat-label-height: 24px;

/* ======== Mat Text ======== */

.mat-mdc-h1, .mat-mdc-typography h1 {
	@extend .H1Black700;
	font-weight: 800;
	margin: 0;
}

.mat-mdc-h2, .mat-mdc-subheading-1, .mat-mdc-typography h2 {
	@extend .H2Black700;
	font-weight: 600;
	margin: 0;
}

.mat-mdc-h3, .mat-mdc-subheading-2, .mat-mdc-typography h3 {
	@extend .H3Black700;
	font-weight: 600;
	margin: 0;
}

.mat-mdc-h4, .mat-mdc-typography h4 {
	@extend .H4Black700Semibold;
	margin: 0;
}

.mat-mdc-h5, .mat-mdc-typography h5 {
	@extend .H5Black700;
	margin: 0;
}

.mat-mdc-h6, .mat-mdc-typography h6 {
	@extend .H6Black500Regular;
	margin: 0;
}

p {
	@extend .H6Black700Regular;
	margin: 0;
	margin-block-start: 0;
	margin-block-end: 0;
}

/* ======== Mat Form Field ======== */

.mat-mdc-row {
	min-height: 8rem;
}

.mdc-floating-label--float-above {
	transform: translateY(-230%) scale(0.75) !important;
}

.mat-mdc-form-field {
	overflow: visible;
}


.mdc-text-field--filled:not(.mdc-text-field--disabled):has(.cdk-text-field-autofill-monitored:-webkit-autofill) {
	background-color: rgb(232, 240, 254);
}

.mat-mdc-form-field-bottom-align::before {
	height: 0 !important;
}

.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-ripple {
	background-color: var(--red) !important;
}

.mat-mdc-form-field-underline {
	bottom: 0 !important;
	.mat-mdc-form-field-ripple {
		background-color: var(--black-500) !important;
	}
}

.mdc-text-field--outlined {
	padding-right: max(16px, var(--mdc-filled-button-padding)) !important;
	padding-left: max(16px, var(--mdc-filled-button-padding)) !important;
}

.mat-mdc-text-field-wrapper {
	height: 100% !important;
	overflow: visible !important;
}

.mat-mdc-input-element:disabled::placeholder{
	color: $disabled-font-color;
}

.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
	transform: translateY(calc(-50% - #{$mat-label-height} / 2 - 8px)) scale(0.75) !important; // Shifts up half the container, half the label, and another 8px for appearance
}

.mat-mdc-form-field-wrapper>.mat-mdc-form-field-flex {
	transition: 0.2s ease;
	padding-top: 0 !important;
}

mat-form-field.mat-mdc-focused {
	.mat-mdc-form-field-wrapper>.mat-mdc-form-field-flex {
		background-color: var(--white-200-opacity);
	}
}

.mat-mdc-form-field.mat-mdc-focused.mat-mdc-ripple {
	background-color: var(--black-500) !important;
}

.mat-mdc-form-field-label-wrapper {
	padding-top: 0 !important;
	top: 0 !important;
}

.mat-mdc-form-field-wrapper {
	padding-bottom: 0 !important;
	height: 100%;
	transition: 0.2s ease-in-out;

	&:hover {
		border-color: #000;
	}
}

.mat-mdc-form-field-subscript-wrapper {
	margin-top: 0 !important;
	top: 100% !important;
}

.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
	padding: 0 !important;
}

.mat-mdc-form-field-flex {
	height: 100%;
	align-items: center !important;
}

.mat-mdc-form-field-disabled {
	pointer-events: none;
	border-color: var(--black-400)!important;
	color: var(--black-400)!important;
}

.mat-mdc-form-field-infix {
	padding: 0 !important;
	border: none;
	display: flex !important;
	height: 100%;
}

.mat-mdc-select-arrow {
	color: var(--black-500) !important;
}

.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
	color: $disabled-font-color !important;
}

.mat-mdc-select-disabled .mat-mdc-select-value {
	color: $disabled-font-color !important;
}

.mat-mdc-select-value, .mat-mdc-select-trigger {
	color: inherit;
}

.mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label,
.mat-mdc-form-field-appearance-outline.mat-mdc-focused .mat-mdc-form-field-outline-thick,
.mat-mdc-form-field.mat-mdc-focused.mat-mdc-primary .mat-mdc-select-arrow {
	color: var(--black-400) !important;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
	color: var(--black-400) !important;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
	top: 0;
}

.mat-mdc-form-field-label {
	top: calc(50% - #{$mat-label-height} / 2);
	margin-top: 0;
}

.mat-mdc-form-field-label-wrapper {
	overflow: visible !important;
	top: 0;
	padding-top: 0;
}

.mat-mdc-form-field-label {
	font-family: "Muli", sans-serif !important;
	font-size: 16px !important;
	font-weight: normal !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1.5 !important;
	letter-spacing: 0.1px !important;
	width: calc(100% - 30px) !important;
	padding: 0 16px;
	margin-top: -0.1em !important;
}

.mat-mdc-notch-piece {
	transition: 0.2s ease;
}

input {
	font-family: "Muli", sans-serif !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1.5 !important;
	letter-spacing: 0.1px !important;
	padding: 4px 8px !important;
	caret-color: #000 !important;

	&:not([disabled]) {
		color: #252525 !important;
	}
}

.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label {
	color: var(--red) !important;
}

.mat-mdc-focused {
	.mat-mdc-form-field-wrapper {
		//transition: 0.2s ease-in-out;
		//border-color: #5588d4 !important;
		//box-shadow: 0 0 3px 0 #5588d4 !important;
	}
}

input {

	&.mat-mdc-input-element {
		margin-top: 0 !important;
	}

	&:-webkit-autofill::first-line {
		font-family: "Muli", sans-serif;
		font-size: 16px;
	  }
}


/* ======== Mat Checkbox ======== */

.mat-mdc-checkbox .mat-mdc-checkbox-ripple {
	left: calc(50% - 15px) !important;
	top: calc(50% - 15px) !important;
	height: 30px !important;
	width: 30px !important;
	font-family: "Muli", sans-serif !important;
}

.mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background {
	background-color: #979797 !important;
}

.mat-mdc-checkbox-checked.mat-mdc-primary .mat-mdc-checkbox-background {
	background-color: var(--blue-500) !important;
}

/* ======== Mat Select and Mat Option ======== */

.mat-mdc-select {
	@extend .H6Black700Regular;
	font-family: "Muli", sans-serif !important;
	color: var(--black-700);
	padding: 0 16px;
	width: 100%;
	margin: 0 !important;
	display: flex !important;
	flex-direction: column;
	justify-content: center;
}

.mat-mdc-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
	transform: none !important;
}

.mat-mdc-option {
	@extend .H6Black700Regular;
}

.mat-mdc-option-text {
	color: var(--black-700);
}

.mat-mdc-menu-item {
	min-width: 190px;
	display: flex !important;
	padding: 0 24px !important;
	line-height: 56px !important;
	height: 56px !important;
}

.mat-mdc-menu-item[disabled] {
	.mat-icon {
		opacity: 0.5;
	}
}

.mat-icon, .mat-mdc-button>.mat-icon, .mat-mdc-unelevated-button>.mat-icon, .mat-mdc-raised-button>.mat-icon, .mat-mdc-outlined-button>.mat-icon {
	 -webkit-user-select: none;
	 user-select: none;
	 background-repeat: no-repeat;
	 display: inline-block;
	 fill: currentColor;
	 height: max-content !important;
	 width: max-content !important;
	 font-size: 24px !important;
	 overflow: hidden;
	.mdc-button__ripple {
		transition: 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
	}
 }


/* ======== Mat Menu ======== */

.mat-mdc-menu-content {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

mat-card {
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.23);
	border-width: 1px;
	box-shadow: 0 3px 4px -4px grey !important;
	padding: 0 !important;
	overflow: hidden;
	border-radius: 0 !important;
}

.mat-mdc-menu-panel {
	height: fit-content !important;
}

.mat-mdc-menu-panel.mat-mdc-menu {
	padding: 24px;

	.rounded-menu-container {
		padding: 0px;
	}
}

.mat-mdc-menu-panel.large-menu {
	max-width: 328px;
}

.mat-mdc-menu-panel.medium-menu {
	max-width: 298px;
	width: 298px;
}

.mat-mdc-menu-panel.logout-menu {
	min-width: max-content; // Required because the menu has an odd appearance until it's fully open
	min-height: 280px;
}

.mat-mdc-menu-panel.little-menu {
	min-width: 190px;

	mat-icon {
		color: var(--black-500);
	}
}

.centered-menu-text .item {
	align-items: center;
	padding: 0 3rem;
}

.unbounded-mat-mdc-menu-panel {
	max-width: none !important;
}


/* ======== Mat List ======== */

mat-list {
	padding: 0 !important;
}

mat-list-item {

	mat-icon {
		color: #6a7483;
		margin-right: 2rem;
	}

	&:hover {
		background-color: $disabled-font-color;
	}
	&:focus {
		outline: none;
	}
}

.mat-mdc-list-item:not(.mat-mdc-list-item-interactive) {
	cursor: pointer !important;
}

.mdc-list-item__content {
	cursor: pointer;
	.mat-mdc-list-item-unscoped-content {
		display: flex;
		align-items: center;
	}
}

.mat-mdc-list-item-disabled {
	color: var(--black-500) !important;

	& * {
		color: var(--black-500) !important;
	}
}

.mat-mdc-divider {
	border-top-color: var(--black-300);
	width: 100%;
}


/* ======== Google Maps Autocomplete custom ======== */
.pac-container .pac-item {
	font-family: "Muli", sans-serif!important;
	line-height: 32px!important;
}

/* ======== Mat Accordion ======== */

mat-expansion-panel,
mat-expansion-panel * {
	transition: none !important;
}

mat-expansion-panel-header {
	@extend .H5Black700;
	font-family: Muli,sans-serif !important;
	font-size: 18px !important;
	font-weight: 600 !important;
}

.mat-mdc-expansion-panel-header {
	padding: 0 4rem !important;
	transition: 0.2s ease-in-out;
}

.mat-mdc-expansion-panel-header.mat-mdc-expanded {
	background-color: var(--black-200);
	border-bottom: 1px solid rgba(0,0,0,.1);
	border-radius: 4px 4px 0 0 !important;
}

.mat-mdc-expansion-panel-header.mat-mdc-expanded:focus {
	background-color: var(--black-200) !important;
}

.mat-mdc-expansion-panel-header.mat-mdc-expanded:hover, .mat-mdc-expansion-panel-header:hover {
	background-color: var(--blue-200) !important;
}

.mat-mdc-expansion-panel-body {
	@include desktop {
		padding: 16px 24px !important;
	}
	@include phone {
		padding: 0 !important;
	}
}

.mat-mdc-content {
	flex-direction: column !important;
}

mat-panel-description {
	font-size: 14px;
}

/* ======= Viewer ======= */
.white-toggled .mat-mdc-slide-toggle-thumb {
	background-color: white !important;
}

.black-toggled .mat-mdc-slide-toggle-thumb {
	background-color: black !important;
}

/* ======== Material Stepper Styles ======== */
.stepper-no-header .mat-mdc-horizontal-stepper-header-container {
	display: none !important;
}

.stepper-with-dots
{
	.mat-mdc-horizontal-stepper-header {
		height: auto;
		padding: 0;
	}

	.mat-mdc-horizontal-content-container {
		padding: 0;
	}

	.mat-mdc-horizontal-stepper-header-container {
		pointer-events: none !important;
		cursor: not-allowed;
		text-align: center;
		justify-content: center;
	}

	.mat-mdc-horizontal-stepper-header .mat-mdc-step-icon {
		margin: 5px;
		width: 8px;
		height: 8px;
		background-color: rgba(0,0,0,0);
		border: 1px solid var(--white);
	}

	.mat-mdc-horizontal-stepper-header .mat-mdc-step-icon-selected {
		border-color: $button-primary-color;
		background-color: $button-primary-color;
	}

	.mat-mdc-stepper-horizontal-line, .mat-mdc-step-icon-content {
		display: none;
	}

	.mat-mdc-step-icon-content {
		display: none;
	}

	.mat-mdc-step-header {
		width: 3rem;
	}

	.mat-mdc-step-header.cdk-program-focused {
		background-color: transparent;
	}
}

// A single overlay pane.
.cdk-overlay-pane.mat-mdc-dialog-panel {
	z-index: $z-index-overlay !important;

	@include phone {
		max-width: 100% !important;
		max-height: 100% !important;
	}

	@media (max-height: 768px) {
		max-height: 100% !important;
	}
}

/* ======= Tab ======= */
.mat-mdc-tab-header-pagination {
	z-index: 1 !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
	flex-grow: 0 !important;
}

.mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-bar {
	background-color: $slide-toggle-bar-color;
}
.mat-mdc-slide-toggle-thumb {
	background-color: $slide-toggle-thumb-color;
}
.mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-thumb {
	background-color: $slide-toggle-thumb-checked;
}

.mdc-tab {
	height: 54px !important;

	.mat-icon {
		font-size: 20px !important;
		width: 20px !important;
		height: 20px !important;
	}
}

.mat-mdc-tab .mdc-tab__text-label {
	gap: 4px;
}

/* ======= Button ======= */
$standard-button-height: 56px;
$secondary-button-height: 40px;
$mini-button-height: 32px;
$mini-button-font-size: 14px;

button {
	cursor: pointer;
}

.mat-mdc-button {
	@extend .H7Black700Semibold;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	min-width: 0 !important;
	line-height: 1 !important;
	overflow: hidden !important;
}

.mat-icon-button:not(.mat-mdc-button-disabled) {
	transition: background-color 0.2s ease;

	&:hover {
		background-color: var(--black-200-opacity);
	}
}

.mat-mdc-unelevated-button:not(:disabled) {
	@extend .CenterH6Black500Regular;
	@extend .mat-mdc-button;
	height: $standard-button-height;
	transition: 0.1s ease;
}

.mat-mdc-flat-button.mat-mdc-primary {
	@extend .CenterH6WhiteSemibold;

	&:not(.mat-mdc-button-disabled):hover {
		background-color: var(--blue-600);
	}
}

.mat-mdc-stroked-button {
	@extend .mat-mdc-button;
	height: 56px;

	&:hover {
		.mat-mdc-button-focus-overlay {
			opacity: 0.12 !important;
		}
	}
}

.mat-mdc-stroked-button.mat-mdc-primary {
	@extend .H7Blue500Semibold;

	.mat-mdc-button-focus-overlay {
		transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1),background-color 200ms cubic-bezier(0.35, 0, 0.25, 1);
	}

	&[disabled] {
		border-color: $disabled-font-color;
	}
}

.mat-mdc-button-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
}

.mat-mdc-stroked-button.mat-mdc-accent:not([disabled]) {
	color: var(--black-700) !important;
	border-color: #6d7785 !important;
}

.mat-mdc-stroked-button:not([disabled]) {
	color: var(--cerulean-blue) !important;
	border-color: var(--cerulean-blue) !important;
}

button.mini-button {
	font-size: $mini-button-font-size !important;
	height:	$mini-button-height !important;
}

button.secondary-button {
	height:	$secondary-button-height;
}

button.back-button {
	padding: 1.5rem 3rem;
	margin: 0;
	color: #005dea;
}

/* ======= Dialog ======= */

.mat-mdc-dialog-container {
	padding: 0 !important;
}

/* ======= Table ======= */

.mat-mdc-header-cell {
	@extend .H7Black500Semibold;
	gap: 0.5rem;
}

.mat-mdc-sort-header-button {
	text-align: left;
}

mat-cell:first-of-type, mat-header-cell:first-of-type {
	padding-left: 3rem !important;
}

mat-cell:last-of-type, mat-header-cell:last-of-type {
	padding-right: 3rem !important;
}

/* ======= Radio Button ======= */

.mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle {
	background-color: var(--cerulean-blue);
}

/* ======= Accordion ======= */

.mat-mdc-accordion .mat-mdc-expansion-panel:last-of-type, .mat-mdc-accordion .mat-mdc-expansion-panel:first-of-type {
	border-radius: 0 !important;
}

/* ======= Slider ======= */

.mat-mdc-slider {
	position: relative !important;
	top: -2rem;
	height: 3rem !important;
	padding: 0 !important;
}

.mat-mdc-slider-wrapper {
	height: 1rem !important;
}

.mat-mdc-slider-track-wrapper {
	background-color: var(--white) !important;
	height: 1rem !important;
	border-radius: 1rem;
}

.mat-mdc-slider-track-background {
	background-color: var(--white) !important;
	height: 1rem !important;
}

.mat-mdc-slider-track-fill {
	background-color: #218ce4 !important;
	height: 1rem !important;
}

.mat-mdc-slider-thumb {
	background-color: #218ce4 !important;
}


h1, h2, h3, h4, h5, h6 {
	font-family: "Muli", sans-serif;
	margin: 0;
}
